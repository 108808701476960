import React from 'react';
import { Route } from 'react-router';

import './App.css';

import Header from './Header';
import Registration from '@watsco/registration-react-module';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="App-content">
        <Route path="/">
          <Registration bu={process.env.REACT_APP_BU_NAME} tac={process.env.REACT_APP_TAC} 
          login={process.env.REACT_APP_LOGIN} forgotPwd={process.env.REACT_APP_FORGOT_PWD}/>
        </Route>
      </div>
    </div>
  );
}

export default App;
