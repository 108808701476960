import React from 'react';
  
import logo from './ecm-white-logo.png';
import phone from './phone.png'

import './Header.css';

function Header() {
    return (
        <header className="Header">
            <div className="Header-primary">
                <a href="https://www.ecmdi.com/"><img src={logo} className="Header-logo" alt="East Coast Metal Distributors"/></a>
                <div className="Header-phone">
                    <img src={phone} className="Header-phone=logo" alt="Call" width="65" height="52" />
                    <div>
                        <p>For Assistance: </p>
                        <p>Call: (844) 227-9531 </p>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;