import 'typeface-roboto';
import './styles/styles.scss';

import * as serviceWorker from './serviceWorker';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter as Router } from "react-router-dom";
import { createClient } from '@watsco/conduitql-apollo-client';

import './index.css';

const client = createClient({
    uri: process.env.REACT_APP_CONDUITQL_URL,
    connectToDevTools: process.env.REACT_APP_APOLLO_CONNECT_DEVTOOLS === 'true'
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <App />
        </Router>
    </ApolloProvider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
