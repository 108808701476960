"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUserData = useUserData;

var _jwtDecode = _interopRequireDefault(require("jwt-decode"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function useUserData() {
  var token = localStorage.getItem('token');
  var payload = (0, _jwtDecode["default"])(token);
  return payload.data || {};
}