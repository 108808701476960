import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';

import introspectionQueryResultData from './generated/graphql.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

export const createClient = ({ uri, connectToDevTools }) => {
    return new ApolloClient({
        connectToDevTools,
        link: ApolloLink.from([
            onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    graphQLErrors.forEach(({ message, locations, path }) =>
                        console.log(
                            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                        )
                    )
                }
                if (networkError) {
                    console.log(`[Network error]: ${networkError}`);
                }
            }),
            setContext((_, { headers }) => {
                const token = localStorage.getItem('token');

                return {
                    headers: {
                        ...headers,
                        authorization: token ? `Bearer ${token}` : ''
                    }
                }
            }),
            new ApolloLink((operation, forward) => {
                return forward(operation).map(response => {
                    const { response: { headers } } = operation.getContext();
                    const auth = headers.get('authorization');
                    if (auth) {
                        localStorage.setItem('token', auth.replace('Bearer ', ''));
                    }
                    return response;
                })
            }),
            new HttpLink({ uri })
        ]),
        cache: new InMemoryCache({ fragmentMatcher })
    });
};